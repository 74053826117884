import React, { useContext } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { onClient } from '../context'
import { userContext } from '../context/user-provider'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ad5389; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
`

const Title = styled.h2`
  margin: 10px;
`
const Close = () => {
  const { user } = useContext(userContext)
  if(onClient()) {
    window.close()
  }
  return (
    <Layout>
      <Wrapper>
        <div className='animate-in'>
          <Title>{user ? 'Du är nu inloggad!' : 'Loading...'}</Title>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default Close